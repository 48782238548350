'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Cauth$SFUI = require("./Cauth.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");

function Hook(Fetcher) {
  var Cauth$prime = Cauth$SFUI.Hook(Fetcher);
  var use = function (defaultBackend) {
    var match = Curry._1(Cauth$prime.use, undefined);
    var cauthDispatch = match[1];
    var cauthState = match[0];
    var match$1 = React.useState(function () {
          return defaultBackend;
        });
    var setAuthBackend = match$1[1];
    var authBackend = match$1[0];
    var authDispatch = function (loginInfos) {
      if (!loginInfos) {
        if (authBackend) {
          return ;
        } else {
          return Curry._1(cauthDispatch, /* Logout */0);
        }
      }
      var loginInfos$1 = loginInfos._0;
      Curry._1(setAuthBackend, (function (param) {
              return /* Cauth */0;
            }));
      return Curry._1(cauthDispatch, /* Login */{
                  _0: loginInfos$1._0,
                  _1: loginInfos$1._1
                });
    };
    var authState = function (param) {
      if (param) {
        return {
                auth_request: /* NotAsked */0,
                user: undefined
              };
      } else {
        return {
                auth_request: cauthState,
                user: Belt_Option.flatMap(Cauth$SFUI.getUser(undefined), (function (uid) {
                        return {
                                name: uid
                              };
                      }))
              };
      }
    };
    return [
            authState(authBackend),
            authDispatch
          ];
  };
  return {
          Cauth$prime: Cauth$prime,
          use: use
        };
}

exports.Hook = Hook;
/* react Not a pure module */
