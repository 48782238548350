'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var V2$SF = require("@softwarefactory-project/re-sf/src/V2.bs.js");
var React = require("react");
var Info$SF = require("@softwarefactory-project/re-sf/src/Info.bs.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Cauth$SFUI = require("./Cauth.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Resources$SF = require("@softwarefactory-project/re-sf/src/Resources.bs.js");
var RemoteApi$SFUI = require("./RemoteApi.bs.js");

var apiKeyEndpoint = "/auth/apikey" + (
  Cauth$SFUI.isDevelopment ? ".json" : ""
);

var userSettingEndpoint = "/manage/services_users" + (
  Cauth$SFUI.isDevelopment ? ".json" : ""
);

function Hook(Fetcher) {
  var RemoteApi = RemoteApi$SFUI.API(Fetcher);
  var use = function (param) {
    return Curry._2(RemoteApi.Hook.useAutoGet, "/api/info.json", Info$SF.decode);
  };
  var Info = {
    use: use
  };
  var use$1 = function (default_tenant) {
    return Curry._2(RemoteApi.Hook.useGet, "/api/resources.json", (function (json) {
                  return Belt_Result.flatMap(Resources$SF.decode(json), (function (resv1) {
                                return {
                                        TAG: /* Ok */0,
                                        _0: V2$SF.fromV1(default_tenant, resv1)
                                      };
                              }));
                }));
  };
  var Resources = {
    use: use$1
  };
  var user_decode = function (v) {
    var dict = Js_json.classify(v);
    if (typeof dict === "number") {
      return Decco.error(undefined, "Not an object", v);
    }
    if (dict.TAG !== /* JSONObject */2) {
      return Decco.error(undefined, "Not an object", v);
    }
    var dict$1 = dict._0;
    var username = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "username"), null));
    if (username.TAG === /* Ok */0) {
      var fullname = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "fullname"), null));
      if (fullname.TAG === /* Ok */0) {
        var email = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "email"), null));
        if (email.TAG === /* Ok */0) {
          var idp_sync = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "idp_sync"), null));
          if (idp_sync.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      username: username._0,
                      fullname: fullname._0,
                      email: email._0,
                      idp_sync: idp_sync._0
                    }
                  };
          }
          var e = idp_sync._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".idp_sync" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = email._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".email" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = fullname._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".fullname" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = username._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".username" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  };
  var user_encode = function (user) {
    return Js_dict.fromList({
                hd: [
                  "username",
                  user.username
                ],
                tl: {
                  hd: [
                    "full_name",
                    user.fullname
                  ],
                  tl: {
                    hd: [
                      "email",
                      user.email
                    ],
                    tl: {
                      hd: [
                        "idp_sync",
                        user.idp_sync
                      ],
                      tl: /* [] */0
                    }
                  }
                }
              });
  };
  var managesfUser_decode = function (v) {
    var dict = Js_json.classify(v);
    if (typeof dict === "number") {
      return Decco.error(undefined, "Not an object", v);
    }
    if (dict.TAG !== /* JSONObject */2) {
      return Decco.error(undefined, "Not an object", v);
    }
    var dict$1 = dict._0;
    var username = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "username"), null));
    if (username.TAG === /* Ok */0) {
      var full_name = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "full_name"), null));
      if (full_name.TAG === /* Ok */0) {
        var email = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "email"), null));
        if (email.TAG === /* Ok */0) {
          var idp_sync = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "idp_sync"), null));
          if (idp_sync.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      username: username._0,
                      full_name: full_name._0,
                      email: email._0,
                      idp_sync: idp_sync._0
                    }
                  };
          }
          var e = idp_sync._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".idp_sync" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = email._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".email" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = full_name._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".full_name" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = username._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".username" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  };
  var userPut_decode = function (v) {
    var dict = Js_json.classify(v);
    if (typeof dict === "number") {
      return Decco.error(undefined, "Not an object", v);
    }
    if (dict.TAG !== /* JSONObject */2) {
      return Decco.error(undefined, "Not an object", v);
    }
    var updated_fields = managesfUser_decode(Belt_Option.getWithDefault(Js_dict.get(dict._0, "updated_fields"), null));
    if (updated_fields.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                updated_fields: updated_fields._0
              }
            };
    }
    var e = updated_fields._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".updated_fields" + e.path,
              message: e.message,
              value: e.value
            }
          };
  };
  var put_decode = function (json) {
    return Belt_Result.flatMap(userPut_decode(json), (function (up) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            username: up.updated_fields.username,
                            fullname: up.updated_fields.full_name,
                            email: up.updated_fields.email,
                            idp_sync: up.updated_fields.idp_sync
                          }
                        };
                }));
  };
  var use$2 = function (user) {
    return Curry._3(RemoteApi.Hook.useSimplePut, userSettingEndpoint + ("?username=" + user), user_decode, put_decode);
  };
  var UserSettings = {
    user_decode: user_decode,
    user_encode: user_encode,
    managesfUser_decode: managesfUser_decode,
    userPut_decode: userPut_decode,
    put_decode: put_decode,
    use: use$2
  };
  var apiKey_encode = function (v) {
    return Js_dict.fromArray([[
                  "api_key",
                  Decco.stringToJson(v.api_key)
                ]]);
  };
  var apiKey_decode = function (v) {
    var dict = Js_json.classify(v);
    if (typeof dict === "number") {
      return Decco.error(undefined, "Not an object", v);
    }
    if (dict.TAG !== /* JSONObject */2) {
      return Decco.error(undefined, "Not an object", v);
    }
    var api_key = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict._0, "api_key"), null));
    if (api_key.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                api_key: api_key._0
              }
            };
    }
    var e = api_key._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".api_key" + e.path,
              message: e.message,
              value: e.value
            }
          };
  };
  var initialState = {
    api_key: /* NotAsked */0,
    delete_goal: "",
    delete_request: /* NotAsked */0
  };
  var goal = function (param) {
    switch (param.TAG | 0) {
      case /* ApiKeyRequest */0 :
          return "Request";
      case /* ApiKeyRegenerate */1 :
          return "Regenerate";
      case /* ApiKeyDelete */2 :
          return "Delete";
      
    }
  };
  var reducer = function (state, action) {
    switch (action.TAG | 0) {
      case /* ApiKeyRequest */0 :
          return {
                  api_key: RemoteApi$SFUI.updateRemoteData(state.api_key, action._0),
                  delete_goal: state.delete_goal,
                  delete_request: state.delete_request
                };
      case /* ApiKeyRegenerate */1 :
      case /* ApiKeyDelete */2 :
          break;
      
    }
    var r = action._0;
    var tmp;
    tmp = typeof r === "number" || r.TAG !== /* NetworkRequestSuccess */0 ? state.api_key : /* NotAsked */0;
    return {
            api_key: tmp,
            delete_goal: goal(action),
            delete_request: RemoteApi$SFUI.updateRemoteData(state.delete_request, r)
          };
  };
  var get = function (dispatch) {
    return Curry._3(RemoteApi.getMaybe, apiKeyEndpoint, apiKey_decode, (function (r) {
                  return Curry._1(dispatch, {
                              TAG: /* ApiKeyRequest */0,
                              _0: r
                            });
                }));
  };
  var $$delete = function (dispatch, intent) {
    return Curry._2(RemoteApi.$$delete, apiKeyEndpoint, (function (r) {
                  return Curry._1(dispatch, Curry._1(intent, r));
                }));
  };
  var create = function (dispatch) {
    return Curry._4(RemoteApi.post, apiKeyEndpoint, undefined, apiKey_decode, (function (r) {
                  return Curry._1(dispatch, {
                              TAG: /* ApiKeyRequest */0,
                              _0: RemoteApi$SFUI.actionMap(r, (function (x) {
                                      return x;
                                    }))
                            });
                }));
  };
  var use$3 = function (param) {
    var match = React.useReducer(reducer, initialState);
    var dispatch = match[1];
    var state = match[0];
    var partial_arg = state.api_key;
    React.useEffect((function () {
            var param;
            return RemoteApi$SFUI.getWhenNeeded(partial_arg, (function (param) {
                          get(dispatch);
                          
                        }), param);
          }), []);
    var callback = function (cbAction) {
      if (cbAction) {
        RemoteApi$SFUI.chainCall($$delete(dispatch, (function (x) {
                    return {
                            TAG: /* ApiKeyRegenerate */1,
                            _0: x
                          };
                  })), (function (param) {
                return create(dispatch);
              }));
      } else {
        $$delete(dispatch, (function (x) {
                return {
                        TAG: /* ApiKeyDelete */2,
                        _0: x
                      };
              }));
      }
      
    };
    return [
            state,
            callback
          ];
  };
  var ApiKey = {
    apiKey_encode: apiKey_encode,
    apiKey_decode: apiKey_decode,
    initialState: initialState,
    goal: goal,
    reducer: reducer,
    get: get,
    $$delete: $$delete,
    create: create,
    use: use$3
  };
  return {
          RemoteApi: RemoteApi,
          Info: Info,
          Resources: Resources,
          UserSettings: UserSettings,
          ApiKey: ApiKey
        };
}

exports.apiKeyEndpoint = apiKeyEndpoint;
exports.userSettingEndpoint = userSettingEndpoint;
exports.Hook = Hook;
/* react Not a pure module */
