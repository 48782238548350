'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var V2$SF = require("@softwarefactory-project/re-sf/src/V2.bs.js");
var React = require("react");
var PFTypes = require("@softwarefactory-project/re-patternfly/src/PFTypes.bs.js");
var Api$SFUI = require("./Api.bs.js");
var Auth$SFUI = require("./Auth.bs.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RemoteApi$SFUI = require("./RemoteApi.bs.js");
var UserLogin$SFUI = require("./UserLogin.bs.js");
var UserSettings$SFUI = require("./UserSettings.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function renderIfSome(x, f) {
  if (x !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(x));
  } else {
    return null;
  }
}

function renderIf(pred, elem) {
  if (pred) {
    return elem;
  } else {
    return null;
  }
}

function renderIfNot(pred) {
  var partial_arg = !pred;
  return function (param) {
    if (partial_arg) {
      return param;
    } else {
      return null;
    }
  };
}

var listToReactArray = Belt_List.toArray;

function renderList(xs, f) {
  return Belt_List.toArray(Belt_List.map(xs, f));
}

function str(prim) {
  return prim;
}

function renderLink(l) {
  return React.createElement("p", {
              key: l.name
            }, React.createElement("a", {
                  style: {
                    color: "#bee1f4"
                  },
                  href: l.link
                }, l.name));
}

function is_empty(v) {
  if (typeof v === "number") {
    return true;
  } else {
    return false;
  }
}

function maybe_attribute(attribute, ctr) {
  if (attribute !== undefined) {
    return Curry._1(ctr, Caml_option.valFromOption(attribute));
  } else {
    return /* Empty */0;
  }
}

function maybe_flat(v) {
  return maybe_attribute(v, (function (v) {
                return {
                        TAG: /* Flat */0,
                        _0: v
                      };
              }));
}

function maybe_link(v) {
  return maybe_attribute(v, (function (v) {
                return {
                        TAG: /* Link */1,
                        _0: v
                      };
              }));
}

function maybe_contacts(v) {
  return maybe_attribute(v, (function (v) {
                return {
                        TAG: /* Contact */2,
                        _0: v
                      };
              }));
}

function buildURL(url, label) {
  return React.createElement("a", {
              href: url
            }, label);
}

var boxStyle = {
  borderRadius: "10px"
};

var boxTitleStyle = {
  backgroundColor: "var(--pf-global--palette--blue-100)",
  borderTopLeftRadius: "10px",
  borderTopRightRadius: "10px"
};

function displayImg(width, height, src, alt) {
  return React.createElement("img", {
              style: {
                marginRight: "5px",
                marginLeft: "5px"
              },
              alt: alt,
              height: height,
              src: src,
              width: width
            });
}

function asset(path) {
  return require("../assets/" + path);
}

function logo(name) {
  return require("../assets/logos/" + name);
}

var gerrit = require("../assets/logos/Gerrit_icon.svg");

var zuul = require("../assets/logos/Zuul_icon.svg");

var nodepool = require("../assets/logos/Nodepool_icon.svg");

var paste = require("../assets/logos/Paste_icon.svg");

var etherpad = require("../assets/logos/Etherpad_icon.svg");

var kibana = require("../assets/logos/Kibana_icon.svg");

var mumble = require("../assets/logos/Mumble_icon.svg");

var cgit = require("../assets/logos/CGIT_icon.svg");

var repoxplorer = require("../assets/logos/repoXplorer_icon.svg");

var hound = require("../assets/logos/Hound_icon.svg");

var grafana = require("../assets/logos/Grafana_icon.svg");

var status = require("../assets/logos/Status_icon.svg");

var avatar = require("@patternfly/react-core/src/components/Avatar/examples/avatarImg.svg");

var Logo = {
  logo: logo,
  gerrit: gerrit,
  zuul: zuul,
  nodepool: nodepool,
  paste: paste,
  etherpad: etherpad,
  kibana: kibana,
  mumble: mumble,
  cgit: cgit,
  repoxplorer: repoxplorer,
  hound: hound,
  grafana: grafana,
  status: status,
  avatar: avatar
};

var Asset = {
  asset: asset,
  Logo: Logo
};

function getLogoImg(param, param$1) {
  return displayImg("75", "75", param, param$1);
}

function App$Service$Logo(Props) {
  var name = Props.name;
  var link = Props.link;
  var displayLogo = function (src, alt) {
    return React.createElement(React.Fragment, undefined, React.createElement("a", {
                    href: link
                  }, getLogoImg(src, alt)), React.createElement("center", undefined, alt));
  };
  switch (name) {
    case "cgit" :
        return displayLogo(cgit, "CGIT");
    case "etherpad" :
        return displayLogo(etherpad, "Etherpad");
    case "gerrit" :
        return displayLogo(gerrit, "Gerrit");
    case "grafana" :
        return displayLogo(grafana, "Grafana");
    case "hound" :
        return displayLogo(hound, "Hound");
    case "kibana" :
        return displayLogo(kibana, "Kibana");
    case "lodgeit" :
        return displayLogo(paste, "Paste");
    case "murmur" :
        return displayLogo(mumble, "Mumble");
    case "nodepool" :
        return displayLogo(nodepool, "Nodepool");
    case "repoxplorer" :
        return displayLogo(repoxplorer, "Repoxplorer");
    case "status" :
        return displayLogo(status, "Zuul Status");
    case "zuul" :
        return displayLogo(zuul, "Zuul");
    default:
      return null;
  }
}

var Logo$1 = {
  make: App$Service$Logo
};

var Service = {
  getLogoImg: getLogoImg,
  Logo: Logo$1
};

function App$SRCard(Props) {
  var sr = Props.sr;
  var cloneUrl = "git clone " + V2$SF.Connection.cloneUrl(sr.location, sr.name);
  var webUrl = buildURL(V2$SF.Connection.webUrl(sr.location, sr.name), "Browse tree");
  var commitUrl = buildURL(V2$SF.Connection.commitUrl(sr.location, sr.name), "Last commits");
  var changesUrl = buildURL(V2$SF.Connection.changesUrl(sr.location, sr.name), "Open changes");
  return React.createElement(ReactCore.Card, {
              children: null,
              isCompact: true,
              style: boxStyle
            }, React.createElement(ReactCore.CardTitle, {
                  children: null
                }, React.createElement("span", undefined, React.createElement("b", undefined, sr.name)), renderIfSome(sr.description, (function (desc) {
                        return React.createElement("span", undefined, " - ", desc);
                      }))), React.createElement(ReactCore.CardBody, {
                  children: React.createElement(React.Fragment, undefined, React.createElement("div", undefined, React.createElement("span", undefined, cloneUrl)), React.createElement("div", undefined, React.createElement("span", undefined, webUrl), React.createElement("span", undefined, " / "), commitUrl, React.createElement("span", undefined, " / "), changesUrl))
                }));
}

var SRCard = {
  make: App$SRCard
};

function App$SRsCard(Props) {
  var srs = Props.srs;
  return React.createElement(ReactCore.Card, {
              children: null,
              isCompact: true,
              style: boxStyle
            }, React.createElement(ReactCore.CardTitle, {
                  children: "Projects' repositories",
                  style: boxTitleStyle
                }), React.createElement(ReactCore.CardBody, {
                  children: null
                }, React.createElement("br", undefined), React.createElement(ReactCore.Grid, {
                      children: Belt_List.toArray(Belt_List.map(srs, (function (sr) {
                                  return React.createElement(ReactCore.GridItem, {
                                              children: React.createElement(App$SRCard, {
                                                    sr: sr,
                                                    key: sr.name
                                                  }),
                                              span: PFTypes.Column._6,
                                              key: sr.name
                                            });
                                }))),
                      hasGutter: true
                    })));
}

var SRsCard = {
  make: App$SRsCard
};

function handleClick(project_id, isClickable, param) {
  if (isClickable) {
    return RescriptReactRouter.push("project/" + project_id);
  }
  
}

function renderAttribute(param) {
  var attribute = param[1];
  var label = param[0];
  var tmp;
  if (typeof attribute === "number") {
    tmp = null;
  } else {
    switch (attribute.TAG | 0) {
      case /* Flat */0 :
          tmp = attribute._0;
          break;
      case /* Link */1 :
          var link = attribute._0;
          tmp = buildURL(link, link);
          break;
      case /* Contact */2 :
          tmp = Belt_List.toArray(Belt_List.map(attribute._0, (function (contact) {
                      return React.createElement("span", {
                                  key: contact
                                }, React.createElement("a", {
                                      href: "mailto:" + contact
                                    }, contact), " ");
                    })));
          break;
      
    }
  }
  return renderIfNot(is_empty(attribute))(React.createElement(ReactCore.ListItem, {
                  children: null,
                  key: label
                }, React.createElement("b", undefined, label + ": "), tmp));
}

function project_attrs(project) {
  return {
          hd: [
            "Website",
            maybe_attribute(project.website, (function (v) {
                    return {
                            TAG: /* Link */1,
                            _0: v
                          };
                  }))
          ],
          tl: {
            hd: [
              "Issue-tracker",
              maybe_attribute(project.issue_tracker_url, (function (v) {
                      return {
                              TAG: /* Link */1,
                              _0: v
                            };
                    }))
            ],
            tl: /* [] */0
          }
        };
}

function project_full_attrs(project) {
  return {
          hd: [
            "Documentation",
            maybe_attribute(project.documentation, (function (v) {
                    return {
                            TAG: /* Link */1,
                            _0: v
                          };
                  }))
          ],
          tl: {
            hd: [
              "Contacts",
              maybe_attribute(project.contacts, (function (v) {
                      return {
                              TAG: /* Contact */2,
                              _0: v
                            };
                    }))
            ],
            tl: {
              hd: [
                "Mailing-lists",
                maybe_attribute(project.mailing_lists, (function (v) {
                        return {
                                TAG: /* Contact */2,
                                _0: v
                              };
                      }))
              ],
              tl: /* [] */0
            }
          }
        };
}

function App$ProjectCard(Props) {
  var project = Props.project;
  var isSmallOpt = Props.isSmall;
  var isSmall = isSmallOpt !== undefined ? isSmallOpt : false;
  var partial_arg = project.name;
  var xs = project_attrs(project);
  var xs$1 = project_full_attrs(project);
  return React.createElement(ReactCore.Card, {
              children: null,
              isCompact: true,
              isSelectable: isSmall,
              onClick: (function (param) {
                  return handleClick(partial_arg, isSmall, param);
                }),
              key: project.name
            }, React.createElement(ReactCore.CardTitle, {
                  children: null
                }, React.createElement("span", undefined, project.name), React.createElement("span", undefined, " - "), React.createElement("span", undefined, project.description)), React.createElement(ReactCore.CardBody, {
                  children: null
                }, React.createElement(ReactCore.List, {
                      children: null
                    }, Belt_List.toArray(Belt_List.map(xs, renderAttribute)), renderIfNot(isSmall)(Belt_List.toArray(Belt_List.map(xs$1, renderAttribute)))), React.createElement("br", undefined), renderIfNot(isSmall)(React.createElement(App$SRsCard, {
                          srs: project.source_repositories
                        }))));
}

var ProjectCard = {
  handleClick: handleClick,
  renderAttribute: renderAttribute,
  project_attrs: project_attrs,
  project_full_attrs: project_full_attrs,
  make: App$ProjectCard
};

function App$TenantCard(Props) {
  var tenant = Props.tenant;
  var puburl = Props.puburl;
  var isLocal = tenant.url === puburl;
  var elem = React.createElement(ReactCore.Stack, {
        children: null
      }, React.createElement(ReactCore.Bullseye, {
            children: "This tenant owns the following projects"
          }), Belt_List.toArray(Belt_List.map(tenant.projects, (function (project) {
                  return React.createElement(App$ProjectCard, {
                              project: project,
                              isSmall: true,
                              key: project.name
                            });
                }))));
  var buildTenantUrl = tenant.url.replace("/manage", "");
  return React.createElement(ReactCore.Card, {
              children: null,
              isCompact: true,
              style: boxStyle,
              key: tenant.name
            }, React.createElement(ReactCore.CardTitle, {
                  children: null,
                  style: boxTitleStyle
                }, React.createElement("span", undefined, tenant.name), React.createElement("span", undefined, " - "), React.createElement("span", undefined, Belt_Option.getWithDefault(tenant.description, "The " + (tenant.name + " tenant")))), React.createElement(ReactCore.CardBody, {
                  children: React.createElement(ReactCore.Stack, {
                        children: null,
                        hasGutter: true
                      }, isLocal ? elem : null, renderIfNot(isLocal)(React.createElement(ReactCore.Bullseye, {
                                children: buildURL(buildTenantUrl, "Tenant deployment - " + buildTenantUrl)
                              })))
                }));
}

var TenantCard = {
  make: App$TenantCard
};

function App$WelcomePage$TenantList(Props) {
  var tenants = Props.tenants;
  var puburl = Props.puburl;
  return React.createElement(ReactCore.Stack, {
              children: Belt_List.toArray(Belt_List.map(tenants, (function (tenant) {
                          return React.createElement(App$TenantCard, {
                                      tenant: tenant,
                                      puburl: puburl,
                                      key: tenant.name
                                    });
                        }))),
              hasGutter: true
            });
}

var TenantList = {
  make: App$WelcomePage$TenantList
};

function App$WelcomePage$SplashLogo(Props) {
  var info = Props.info;
  return React.createElement(ReactCore.Bullseye, {
              children: React.createElement("img", {
                    height: "250",
                    src: "data:image/png;base64," + info.splash_image_b64data,
                    width: "250"
                  })
            });
}

var SplashLogo = {
  make: App$WelcomePage$SplashLogo
};

function App$WelcomePage$Menu(Props) {
  var services = Props.services;
  return React.createElement(ReactCore.Bullseye, {
              children: Belt_List.toArray(Belt_List.map(services, (function (service) {
                          return React.createElement(ReactCore.GridItem, {
                                      children: React.createElement(App$Service$Logo, {
                                            name: service.name,
                                            link: service.path
                                          }),
                                      span: PFTypes.Column._1,
                                      key: service.name
                                    });
                        })))
            });
}

var Menu = {
  make: App$WelcomePage$Menu
};

function App$WelcomePage(Props) {
  var info = Props.info;
  var resourcesHook = Props.resourcesHook;
  var dispatch = resourcesHook[1];
  var state = resourcesHook[0];
  React.useEffect((function () {
          return RemoteApi$SFUI.getWhenNeeded(state, dispatch, undefined);
        }), []);
  var tmp;
  var exit = 0;
  var resources;
  if (typeof state === "number") {
    tmp = React.createElement("p", undefined, "Loading...");
  } else {
    switch (state.TAG | 0) {
      case /* Loading */0 :
          var resources$1 = state._0;
          if (resources$1 !== undefined) {
            resources = resources$1;
            exit = 1;
          } else {
            tmp = React.createElement("p", undefined, "Loading...");
          }
          break;
      case /* Failure */1 :
          tmp = React.createElement(ReactCore.Alert, {
                title: state._0,
                variant: "danger"
              });
          break;
      case /* Success */2 :
          resources = state._0;
          exit = 1;
          break;
      
    }
  }
  if (exit === 1) {
    tmp = React.createElement(App$WelcomePage$TenantList, {
          tenants: resources.tenants,
          puburl: resources.puburl
        });
  }
  return React.createElement(ReactCore.Stack, {
              children: null,
              hasGutter: true
            }, React.createElement(App$WelcomePage$SplashLogo, {
                  info: info
                }), React.createElement(App$WelcomePage$Menu, {
                  services: info.services
                }), React.createElement(ReactCore.Grid, {
                  children: React.createElement(ReactCore.GridItem, {
                        children: tmp,
                        offset: PFTypes.Column._1,
                        span: PFTypes.Column._10
                      }),
                  hasGutter: true
                }));
}

var WelcomePage = {
  TenantList: TenantList,
  SplashLogo: SplashLogo,
  Menu: Menu,
  make: App$WelcomePage
};

function App$ProjectPage(Props) {
  var project_id = Props.project_id;
  var resourcesHook = Props.resourcesHook;
  var dispatch = resourcesHook[1];
  var state = resourcesHook[0];
  React.useEffect((function () {
          return RemoteApi$SFUI.getWhenNeeded(state, dispatch, undefined);
        }), []);
  var resources;
  if (typeof state === "number") {
    return React.createElement("p", undefined, "Loading...");
  }
  switch (state.TAG | 0) {
    case /* Loading */0 :
        var resources$1 = state._0;
        if (resources$1 === undefined) {
          return React.createElement("p", undefined, "Loading...");
        }
        resources = resources$1;
        break;
    case /* Failure */1 :
        return React.createElement(ReactCore.Alert, {
                    title: state._0,
                    variant: "danger"
                  });
    case /* Success */2 :
        resources = state._0;
        break;
    
  }
  var maybeProject = Belt_List.head(Belt_List.keep(Belt_List.flatten(Belt_List.map(resources.tenants, (function (tenant) {
                      return tenant.projects;
                    }))), (function (project) {
              return project.name === project_id;
            })));
  if (maybeProject !== undefined) {
    return React.createElement(App$ProjectCard, {
                project: maybeProject
              });
  } else {
    return React.createElement("p", undefined, "Project " + (project_id + " not found"));
  }
}

var ProjectPage = {
  make: App$ProjectPage
};

function App$SFAbout(Props) {
  var info = Props.info;
  var isOpen = Props.isOpen;
  var onClose = Props.onClose;
  var elem = React.createElement(React.Fragment, undefined, React.createElement(ReactCore.TextListItem, {
            children: "Contacts",
            component: "dt"
          }), React.createElement(ReactCore.TextListItem, {
            children: Belt_List.toArray(Belt_List.map(info.links.contact, renderLink)),
            component: "dd"
          }));
  var pred = UserLogin$SFUI.notEmpty(info.links.contact);
  return React.createElement(ReactCore.AboutModal, {
              children: React.createElement(ReactCore.TextList, {
                    children: null,
                    component: "dl"
                  }, React.createElement(ReactCore.TextListItem, {
                        children: "Software Factory Version",
                        component: "dt"
                      }), React.createElement(ReactCore.TextListItem, {
                        children: info.version,
                        component: "dd"
                      }), pred ? elem : null),
              brandImageAlt: "SF",
              brandImageSrc: "data:image/png;base64," + info.header_logo_b64data,
              isOpen: isOpen,
              onClose: onClose
            });
}

var SFAbout = {
  make: App$SFAbout
};

function Main(Fetcher) {
  var Auth$prime = Auth$SFUI.Hook(Fetcher);
  var Hook = Api$SFUI.Hook(Fetcher);
  var UserSettingsPage = UserSettings$SFUI.Page(Fetcher);
  var getHeaderLogo = function (info) {
    return React.createElement(ReactCore.Brand, {
                alt: "SF",
                onClick: (function (param) {
                    return RescriptReactRouter.push("/");
                  }),
                src: "data:image/png;base64," + info.header_logo_b64data
              });
  };
  var getBack = function (qs) {
    return Caml_option.nullable_to_opt(new URLSearchParams(qs).get("back"));
  };
  var getBaseUrl = function (param) {
    return window.location.origin;
  };
  var App$Main$Footer = function (Props) {
    var info = Props.info;
    var elem = React.createElement(ReactCore.GridItem, {
          children: React.createElement(ReactCore.Bullseye, {
                children: React.createElement(ReactCore.Stack, {
                      children: null
                    }, React.createElement("p", undefined, React.createElement("b", undefined, "CONTACTS")), Belt_List.toArray(Belt_List.map(info.links.contact, renderLink)))
              }),
          span: PFTypes.Column._4
        });
    var pred = UserLogin$SFUI.notEmpty(info.links.contact);
    return React.createElement(ReactCore.Stack, {
                children: React.createElement(ReactCore.Bullseye, {
                      children: React.createElement(ReactCore.Grid, {
                            children: null,
                            hasGutter: true
                          }, React.createElement(ReactCore.GridItem, {
                                children: React.createElement(ReactCore.Bullseye, {
                                      children: React.createElement(ReactCore.Stack, {
                                            children: null
                                          }, React.createElement("p", undefined, React.createElement("b", undefined, "DOCUMENTATION")), Belt_List.toArray(Belt_List.map(info.links.documentation, renderLink)))
                                    }),
                                span: PFTypes.Column._4
                              }), React.createElement(ReactCore.GridItem, {
                                children: React.createElement(ReactCore.Bullseye, {
                                      children: React.createElement(ReactCore.Stack, {
                                            children: null
                                          }, React.createElement("p", undefined, React.createElement("b", undefined, "PLATFORM")), React.createElement("p", undefined, "version: " + info.version), Belt_List.toArray(Belt_List.map(info.links.status, renderLink)))
                                    }),
                                span: PFTypes.Column._4
                              }), pred ? elem : null)
                    }),
                hasGutter: true
              });
  };
  var Footer = {
    make: App$Main$Footer
  };
  var App$Main$MainWithContext = function (Props) {
    var info = Props.info;
    var resourcesHook = Props.resourcesHook;
    var auth = Curry._1(Auth$prime.use, /* Cauth */0);
    var match = React.useState(function () {
          return false;
        });
    var setModal = match[1];
    var header = React.createElement(ReactCore.PageHeader, {
          headerTools: React.createElement(ReactCore.PageHeaderTools, {
                children: null
              }, React.createElement(ReactCore.PageHeaderToolsItem, {
                    children: React.createElement(ReactCore.Button, {
                          children: React.createElement(ReactIcons.HelpIcon, {}),
                          onClick: (function (param) {
                              return Curry._1(setModal, (function (param) {
                                            return true;
                                          }));
                            }),
                          variant: "plain"
                        })
                  }), React.createElement(UserLogin$SFUI.Header.make, {
                    auth: auth
                  })),
          logo: getHeaderLogo(info)
        });
    var reacturl = RescriptReactRouter.useUrl(undefined, undefined);
    var match$1 = reacturl.path;
    var tmp;
    var exit = 0;
    if (match$1) {
      switch (match$1.hd) {
        case "auth" :
            var match$2 = match$1.tl;
            if (match$2 && match$2.hd === "settings" && !match$2.tl) {
              var user = auth[0].user;
              tmp = user !== undefined ? React.createElement(UserSettingsPage.make, {
                      username: user.name
                    }) : React.createElement("p", undefined, "You need to login first");
            } else {
              exit = 1;
            }
            break;
        case "login" :
            if (match$1.tl) {
              exit = 1;
            } else {
              var back = Belt_Option.getWithDefault(getBack(reacturl.search), window.location.origin);
              tmp = React.createElement(UserLogin$SFUI.Page.make, {
                    info: info,
                    auth: auth,
                    back: back
                  });
            }
            break;
        case "logout" :
            if (match$1.tl) {
              exit = 1;
            } else {
              tmp = React.createElement(UserLogin$SFUI.Logout.make, {
                    auth: auth
                  });
            }
            break;
        case "project" :
            var match$3 = match$1.tl;
            if (match$3 && !match$3.tl) {
              tmp = React.createElement(App$ProjectPage, {
                    project_id: match$3.hd,
                    resourcesHook: resourcesHook
                  });
            } else {
              exit = 1;
            }
            break;
        default:
          exit = 1;
      }
    } else {
      tmp = React.createElement(App$WelcomePage, {
            info: info,
            resourcesHook: resourcesHook
          });
    }
    if (exit === 1) {
      tmp = React.createElement("p", undefined, "Not found");
    }
    return React.createElement(ReactCore.Page, {
                children: null,
                header: header
              }, React.createElement(ReactCore.PageSection, {
                    children: tmp,
                    isFilled: true
                  }), React.createElement(App$SFAbout, {
                    info: info,
                    isOpen: match[0],
                    onClose: (function (param) {
                        return Curry._1(setModal, (function (param) {
                                      return false;
                                    }));
                      })
                  }), React.createElement(ReactCore.PageSection, {
                    children: React.createElement(App$Main$Footer, {
                          info: info
                        }),
                    isFilled: true,
                    variant: "darker"
                  }));
  };
  var MainWithContext = {
    make: App$Main$MainWithContext
  };
  var App$Main$MainWithInfo = function (Props) {
    var info = Props.info;
    var resourcesHook = Curry._1(Hook.Resources.use, "local");
    return React.createElement(App$Main$MainWithContext, {
                info: info,
                resourcesHook: resourcesHook
              });
  };
  var MainWithInfo = {
    make: App$Main$MainWithInfo
  };
  var App$Main = function (Props) {
    var title = Curry._1(Hook.Info.use, undefined);
    if (typeof title === "number") {
      return React.createElement("p", undefined, "Loading...");
    }
    switch (title.TAG | 0) {
      case /* Loading */0 :
          var info = title._0;
          if (info !== undefined) {
            return React.createElement(App$Main$MainWithInfo, {
                        info: info
                      });
          } else {
            return React.createElement("p", undefined, "Loading...");
          }
      case /* Failure */1 :
          return React.createElement(ReactCore.Alert, {
                      title: title._0,
                      variant: "danger"
                    });
      case /* Success */2 :
          return React.createElement(App$Main$MainWithInfo, {
                      info: title._0
                    });
      
    }
  };
  return {
          Auth$prime: Auth$prime,
          Hook: Hook,
          UserSettingsPage: UserSettingsPage,
          getHeaderLogo: getHeaderLogo,
          getBack: getBack,
          getBaseUrl: getBaseUrl,
          Footer: Footer,
          MainWithContext: MainWithContext,
          MainWithInfo: MainWithInfo,
          make: App$Main
        };
}

exports.renderIfSome = renderIfSome;
exports.renderIf = renderIf;
exports.renderIfNot = renderIfNot;
exports.listToReactArray = listToReactArray;
exports.renderList = renderList;
exports.str = str;
exports.renderLink = renderLink;
exports.is_empty = is_empty;
exports.maybe_attribute = maybe_attribute;
exports.maybe_flat = maybe_flat;
exports.maybe_link = maybe_link;
exports.maybe_contacts = maybe_contacts;
exports.buildURL = buildURL;
exports.boxStyle = boxStyle;
exports.boxTitleStyle = boxTitleStyle;
exports.displayImg = displayImg;
exports.Asset = Asset;
exports.Service = Service;
exports.SRCard = SRCard;
exports.SRsCard = SRsCard;
exports.ProjectCard = ProjectCard;
exports.TenantCard = TenantCard;
exports.WelcomePage = WelcomePage;
exports.ProjectPage = ProjectPage;
exports.SFAbout = SFAbout;
exports.Main = Main;
/* gerrit Not a pure module */
