'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_array = require("bs-platform/lib/js/caml_array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Cookie$JustgageReasonCookie = require("@justgage/reason-cookie/src/Cookie.bs.js");

function getRawCookie(param) {
  return Cookie$JustgageReasonCookie.getAsString("auth_pubtkt");
}

var BrowserCookieFetcher = {
  getRawCookie: getRawCookie
};

function AbstractCauthCookie(CF) {
  var getCookie = function (param) {
    return Belt_Option.flatMap(Curry._1(CF.getRawCookie, undefined), (function (c) {
                  return decodeURIComponent(c);
                }));
  };
  var getUser = function (param) {
    var captureUid = function (kv) {
      var rex = new RegExp("^uid=(.*)$").exec(kv);
      if (rex !== null) {
        return Caml_option.nullable_to_opt(Caml_array.get(rex, 1));
      }
      
    };
    return Belt_Option.getWithDefault(Belt_Option.flatMap(getCookie(undefined), (function (c) {
                      return Belt_List.head(Belt_List.keep(Belt_List.map($$String.split_on_char(/* ";" */59, c), captureUid), Belt_Option.isSome));
                    })), undefined);
  };
  var remove = function (param) {
    return Cookie$JustgageReasonCookie.remove("auth_pubtkt");
  };
  return {
          getCookie: getCookie,
          getUser: getUser,
          remove: remove
        };
}

function getCookie(param) {
  return Belt_Option.flatMap(Cookie$JustgageReasonCookie.getAsString("auth_pubtkt"), (function (c) {
                return decodeURIComponent(c);
              }));
}

function getUser(param) {
  var captureUid = function (kv) {
    var rex = new RegExp("^uid=(.*)$").exec(kv);
    if (rex !== null) {
      return Caml_option.nullable_to_opt(Caml_array.get(rex, 1));
    }
    
  };
  return Belt_Option.getWithDefault(Belt_Option.flatMap(getCookie(undefined), (function (c) {
                    return Belt_List.head(Belt_List.keep(Belt_List.map($$String.split_on_char(/* ";" */59, c), captureUid), Belt_Option.isSome));
                  })), undefined);
}

function remove(param) {
  return Cookie$JustgageReasonCookie.remove("auth_pubtkt");
}

var CauthCookie = {
  getCookie: getCookie,
  getUser: getUser,
  remove: remove
};

exports.BrowserCookieFetcher = BrowserCookieFetcher;
exports.AbstractCauthCookie = AbstractCauthCookie;
exports.CauthCookie = CauthCookie;
/* Cookie-JustgageReasonCookie Not a pure module */
