'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Api$SFUI = require("./Api.bs.js");
var RemoteData = require("remotedata-re/src/RemoteData.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReactCore = require("@patternfly/react-core");

function Page(Fetcher) {
  var Hook = Api$SFUI.Hook(Fetcher);
  var boxStyle = {
    borderRadius: "10px"
  };
  var boxTitleStyle = {
    backgroundColor: "var(--pf-global--palette--blue-100)",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px"
  };
  var UserSettings$Page$SettingForm = function (Props) {
    var user = Props.user;
    var callback = Props.callback;
    var match = React.useState(function () {
          return user.idp_sync;
        });
    var setIdpSync = match[1];
    var idpSync = match[0];
    var match$1 = React.useState(function () {
          return user.fullname;
        });
    var setFullname = match$1[1];
    var fullname = match$1[0];
    var match$2 = React.useState(function () {
          return user.email;
        });
    var seteMail = match$2[1];
    var email = match$2[0];
    return React.createElement(ReactCore.Card, {
                children: null,
                isCompact: true,
                style: boxStyle
              }, React.createElement(ReactCore.CardTitle, {
                    children: "User information",
                    style: boxTitleStyle
                  }), React.createElement(ReactCore.CardBody, {
                    children: React.createElement(ReactCore.Form, {
                          children: null
                        }, React.createElement(ReactCore.FormGroup, {
                              children: React.createElement(ReactCore.TextInput, {
                                    id: "username",
                                    isReadOnly: true,
                                    value: user.username
                                  }),
                              fieldId: "username",
                              label: "Username"
                            }), React.createElement(ReactCore.FormGroup, {
                              children: React.createElement(ReactCore.TextInput, {
                                    id: "fullName",
                                    isReadOnly: idpSync,
                                    onChange: (function (value, param) {
                                        return Curry._1(setFullname, (function (param) {
                                                      return value;
                                                    }));
                                      }),
                                    value: fullname
                                  }),
                              fieldId: "fullName",
                              label: "Full name"
                            }), React.createElement(ReactCore.FormGroup, {
                              children: React.createElement(ReactCore.TextInput, {
                                    id: "eMail",
                                    isReadOnly: idpSync,
                                    onChange: (function (value, param) {
                                        return Curry._1(seteMail, (function (param) {
                                                      return value;
                                                    }));
                                      }),
                                    value: email
                                  }),
                              fieldId: "eMail",
                              label: "eMail"
                            }), React.createElement(ReactCore.FormGroup, {
                              children: React.createElement(ReactCore.Checkbox, {
                                    id: "idpSync",
                                    isChecked: idpSync,
                                    label: "Synchronize user data with the Identity Provider",
                                    onChange: (function (param, param$1) {
                                        return Curry._1(setIdpSync, (function (param) {
                                                      return !idpSync;
                                                    }));
                                      })
                                  }),
                              fieldId: "idp",
                              label: "Manage Identity Provider"
                            }), React.createElement(ReactCore.ActionGroup, {
                              children: React.createElement(ReactCore.Button, {
                                    children: "Save",
                                    onClick: (function (param) {
                                        Curry._1(callback, Caml_option.some(Curry._1(Hook.UserSettings.user_encode, {
                                                      username: user.username,
                                                      fullname: fullname,
                                                      email: email,
                                                      idp_sync: idpSync
                                                    })));
                                        
                                      }),
                                    variant: "primary"
                                  })
                            }))
                  }));
  };
  var SettingForm = {
    make: UserSettings$Page$SettingForm
  };
  var UserSettings$Page$ApiForm = function (Props) {
    var match = Curry._1(Hook.ApiKey.use, undefined);
    var callback = match[1];
    var state = match[0];
    var match$1 = state.delete_request;
    var match$2 = state.api_key;
    var match$3;
    var exit = 0;
    var exit$1 = 0;
    if (typeof match$1 === "number") {
      exit$1 = 3;
    } else {
      switch (match$1.TAG | 0) {
        case /* Loading */0 :
            match$3 = [
              React.createElement(ReactCore.Alert, {
                    title: "Deleting...",
                    variant: "info"
                  }),
              ""
            ];
            break;
        case /* Failure */1 :
            match$3 = [
              React.createElement(ReactCore.Alert, {
                    title: state.delete_goal + (" failed: " + match$1._0),
                    variant: "danger"
                  }),
              Belt_Option.getWithDefault(Belt_Option.flatMap(RemoteData.withDefault({
                            api_key: "unknown"
                          }, state.api_key), (function (ak) {
                          return ak.api_key;
                        })), "")
            ];
            break;
        case /* Success */2 :
            if (typeof match$2 === "number") {
              exit = 1;
            } else {
              switch (match$2.TAG | 0) {
                case /* Loading */0 :
                case /* Failure */1 :
                    exit$1 = 3;
                    break;
                case /* Success */2 :
                    var apiKey = match$2._0;
                    if (apiKey !== undefined) {
                      match$3 = [
                        React.createElement(ReactCore.Alert, {
                              title: "New key generated",
                              variant: "info"
                            }),
                        apiKey.api_key
                      ];
                    } else {
                      exit = 1;
                    }
                    break;
                
              }
            }
            break;
        
      }
    }
    if (exit$1 === 3) {
      if (typeof match$2 === "number") {
        exit = 2;
      } else {
        switch (match$2.TAG | 0) {
          case /* Loading */0 :
              var match$4 = match$2._0;
              if (match$4 !== undefined) {
                var apiKey$1 = Caml_option.valFromOption(match$4);
                if (apiKey$1 !== undefined) {
                  match$3 = [
                    null,
                    apiKey$1.api_key
                  ];
                } else {
                  exit = 2;
                }
              } else {
                exit = 2;
              }
              break;
          case /* Failure */1 :
              match$3 = [
                React.createElement(ReactCore.Alert, {
                      title: match$2._0,
                      variant: "danger"
                    }),
                ""
              ];
              break;
          case /* Success */2 :
              var apiKey$2 = match$2._0;
              match$3 = apiKey$2 !== undefined ? [
                  null,
                  apiKey$2.api_key
                ] : [
                  null,
                  ""
                ];
              break;
          
        }
      }
    }
    switch (exit) {
      case 1 :
          match$3 = [
            React.createElement(ReactCore.Alert, {
                  title: "Key deleted",
                  variant: "info"
                }),
            ""
          ];
          break;
      case 2 :
          match$3 = [
            React.createElement("p", undefined, "loading"),
            ""
          ];
          break;
      
    }
    var apiKeyStr = match$3[1];
    return React.createElement(ReactCore.Card, {
                children: null,
                isCompact: true,
                style: boxStyle
              }, React.createElement(ReactCore.CardTitle, {
                    children: "API Key",
                    style: boxTitleStyle
                  }), React.createElement(ReactCore.CardBody, {
                    children: React.createElement(ReactCore.Form, {
                          children: null
                        }, match$3[0], React.createElement(ReactCore.FormGroup, {
                              children: React.createElement(ReactCore.TextInput, {
                                    id: "key",
                                    isReadOnly: true,
                                    value: apiKeyStr
                                  }),
                              fieldId: "key",
                              label: "Key"
                            }), React.createElement(ReactCore.ActionGroup, {
                              children: null
                            }, React.createElement(ReactCore.Button, {
                                  children: "Generate new API key",
                                  onClick: (function (param) {
                                      return Curry._1(callback, /* Regenerate */1);
                                    }),
                                  variant: "primary"
                                }), apiKeyStr === "" ? null : React.createElement(ReactCore.Button, {
                                    children: "Delete API key",
                                    onClick: (function (param) {
                                        return Curry._1(callback, /* Delete */0);
                                      }),
                                    variant: "primary"
                                  })))
                  }));
  };
  var ApiForm = {
    make: UserSettings$Page$ApiForm
  };
  var UserSettings$Page = function (Props) {
    var username = Props.username;
    var match = Curry._1(Hook.UserSettings.use, username);
    var state = match[0];
    var tmp;
    var exit = 0;
    var user;
    if (typeof state === "number") {
      tmp = React.createElement("p", undefined, "Loading");
    } else {
      switch (state.TAG | 0) {
        case /* Loading */0 :
            var user$1 = state._0;
            if (user$1 !== undefined) {
              user = user$1;
              exit = 1;
            } else {
              tmp = React.createElement("p", undefined, "Loading");
            }
            break;
        case /* Failure */1 :
            tmp = React.createElement(ReactCore.Alert, {
                  title: state._0,
                  variant: "danger"
                });
            break;
        case /* Success */2 :
            user = state._0;
            exit = 1;
            break;
        
      }
    }
    if (exit === 1) {
      tmp = React.createElement(UserSettings$Page$SettingForm, {
            user: user,
            callback: match[1]
          });
    }
    return React.createElement(ReactCore.Stack, {
                children: null,
                hasGutter: true
              }, tmp, React.createElement(UserSettings$Page$ApiForm, {}));
  };
  return {
          Hook: Hook,
          boxStyle: boxStyle,
          boxTitleStyle: boxTitleStyle,
          SettingForm: SettingForm,
          ApiForm: ApiForm,
          make: UserSettings$Page
        };
}

exports.Page = Page;
/* react Not a pure module */
