'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Cauth$SFUI = require("./Cauth.bs.js");
var RemoteData = require("remotedata-re/src/RemoteData.bs.js");
var RemoteApi$SFUI = require("./RemoteApi.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function notEmpty(xs) {
  if (xs) {
    return true;
  } else {
    return false;
  }
}

function UserLogin$Page(Props) {
  var info = Props.info;
  var auth = Props.auth;
  var back = Props.back;
  var authDispatch = auth[1];
  var authState = auth[0];
  React.useEffect((function () {
          Curry._1(authDispatch, /* Logout */0);
          
        }), []);
  var match = React.useState(function () {
        return false;
      });
  var toggleLocalAccount = match[1];
  var useLocalAccount = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setUsernameValue = match$1[1];
  var usernameValue = match$1[0];
  var onChangeUsername = function (value, param) {
    return Curry._1(setUsernameValue, (function (param) {
                  return value;
                }));
  };
  var match$2 = React.useState(function () {
        return "";
      });
  var setPasswordValue = match$2[1];
  var passwordValue = match$2[0];
  var onChangePassword = function (value, param) {
    return Curry._1(setPasswordValue, (function (param) {
                  return value;
                }));
  };
  var idpAvailable = (
    info.auths.oauth ? true : false
  ) || (
    info.auths.other ? true : false
  );
  var loginSubtitle = useLocalAccount ? "Sign in with your username and password" : (
      idpAvailable ? "Sign in with your external account" : "Only internal accounts are available"
    );
  var footerListItems = React.createElement(React.Fragment, undefined, React.createElement(ReactCore.ListItem, {
            children: React.createElement(ReactCore.LoginFooterItem, {
                  children: "Terms of Use",
                  href: "#"
                })
          }), React.createElement(ReactCore.ListItem, {
            children: React.createElement(ReactCore.LoginFooterItem, {
                  children: "Help",
                  href: "#"
                })
          }), React.createElement(ReactCore.ListItem, {
            children: React.createElement(ReactCore.LoginFooterItem, {
                  children: "Privacy Policy",
                  href: "#"
                })
          }));
  var helperText = React.createElement(React.Fragment, undefined, React.createElement(ReactIcons.ExclamationCircleIcon, {}), "Invalid login credentials.");
  var mkOauthButton = function (backend, icon, text) {
    return React.createElement("form", {
                key: text,
                action: "/auth/login",
                method: "post",
                target: "_top"
              }, React.createElement("input", {
                    name: "back",
                    type: "hidden",
                    value: back
                  }), React.createElement("input", {
                    name: "method",
                    type: "hidden",
                    value: Cauth$SFUI.backendMethod(backend)
                  }), React.createElement(ReactCore.Button, {
                    children: null,
                    isBlock: true,
                    type: "submit"
                  }, icon, text));
  };
  var oauthButton = function (param) {
    switch (param) {
      case "bitbucket" :
          return mkOauthButton(/* BitBucket */2, null, "Log in with BitBucket");
      case "github" :
          return mkOauthButton(/* GitHub */0, React.createElement(ReactIcons.GithubIcon, {}), " Log in with Github");
      case "google" :
          return mkOauthButton(/* Google */1, React.createElement(ReactIcons.GoogleIcon, {}), " Log in with Google");
      default:
        return null;
    }
  };
  var otherButton = function (param) {
    switch (param.name) {
      case "SAML2" :
          return mkOauthButton(/* SAML */5, null, param.text);
      case "openid" :
          return mkOauthButton(/* OpenID */3, null, param.text);
      case "openid_connect" :
          return mkOauthButton(/* OpenIDConnect */4, null, param.text);
      default:
        return null;
    }
  };
  var externalIdp = Belt_List.toArray(Belt_List.concat(Belt_List.map(info.auths.oauth, oauthButton), Belt_List.map(info.auths.other, otherButton)));
  var onLoginButtonClick = function (e) {
    e.preventDefault();
    return Curry._1(authDispatch, /* Login */{
                _0: /* CauthLogin */{
                  _0: /* Password */{
                    _0: {
                      username: usernameValue,
                      password: passwordValue
                    }
                  },
                  _1: back
                }
              });
  };
  var loginForm = React.createElement(ReactCore.LoginForm, {
        helperText: helperText,
        onChangePassword: onChangePassword,
        onChangeUsername: onChangeUsername,
        onLoginButtonClick: onLoginButtonClick,
        passwordValue: passwordValue,
        usernameValue: usernameValue
      });
  var match$3 = authState.auth_request;
  if (typeof match$3 !== "number" && match$3.TAG === /* Success */2) {
    RescriptReactRouter.push(back);
    window.location.reload();
    return React.createElement("p", undefined, "If you are not redirected, click /");
  }
  return React.createElement(ReactCore.LoginPage, {
              children: null,
              brandImgSrc: "data:image/png;base64," + info.splash_image_b64data,
              footerListItems: footerListItems,
              footerListVariants: "inline",
              loginSubtitle: loginSubtitle,
              loginTitle: "Log in to your account"
            }, RemoteData.isLoading(authState.auth_request) ? React.createElement(ReactCore.Spinner, {}) : null, RemoteApi$SFUI.renderError(authState.auth_request), React.createElement(ReactCore.Stack, {
                  children: null,
                  hasGutter: true
                }, useLocalAccount ? loginForm : externalIdp, React.createElement("a", {
                      onClick: (function (param) {
                          return Curry._1(toggleLocalAccount, (function (param) {
                                        return !useLocalAccount;
                                      }));
                        })
                    }, "Toggle login form")));
}

var Page = {
  make: UserLogin$Page
};

function UserLogin$Logout(Props) {
  var auth = Props.auth;
  React.useEffect((function () {
          Curry._1(auth[1], /* Logout */0);
          RescriptReactRouter.replace("/");
          return (function (param) {
                    
                  });
        }), []);
  return React.createElement("p", undefined, "Logout in progress ...");
}

var Logout = {
  make: UserLogin$Logout
};

function UserLogin$Header(Props) {
  var auth = Props.auth;
  var match = auth[0].user;
  var tmp;
  if (match !== undefined) {
    tmp = React.createElement(ReactCore.PageHeaderToolsGroup, {
          children: null
        }, React.createElement(ReactCore.PageHeaderToolsItem, {
              children: React.createElement("p", undefined, "Welcome " + match.name)
            }), React.createElement(ReactCore.PageHeaderToolsItem, {
              children: React.createElement(ReactCore.Button, {
                    children: React.createElement(ReactIcons.CogIcon, {}),
                    onClick: (function (param) {
                        return RescriptReactRouter.push("/auth/settings");
                      }),
                    variant: "plain"
                  })
            }), React.createElement(ReactCore.PageHeaderToolsItem, {
              children: React.createElement(ReactCore.Button, {
                    children: "Logout",
                    onClick: (function (param) {
                        return RescriptReactRouter.push("/logout");
                      }),
                    variant: "secondary"
                  })
            }));
  } else {
    var dispatch = auth[1];
    tmp = React.createElement(ReactCore.Button, {
          children: "Login",
          onClick: (function (param) {
              Curry._1(dispatch, /* Logout */0);
              return RescriptReactRouter.push("/login");
            }),
          variant: "secondary"
        });
  }
  return React.createElement(ReactCore.PageHeaderToolsGroup, {
              children: tmp
            });
}

var Header = {
  make: UserLogin$Header
};

exports.notEmpty = notEmpty;
exports.Page = Page;
exports.Logout = Logout;
exports.Header = Header;
/* react Not a pure module */
