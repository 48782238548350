'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var SFCookie$SFUI = require("./SFCookie.bs.js");
var RemoteApi$SFUI = require("./RemoteApi.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var Cookie$JustgageReasonCookie = require("@justgage/reason-cookie/src/Cookie.bs.js");

function cauthArgs_encode(v) {
  return Js_dict.fromArray([
              [
                "username",
                Decco.optionToJson(Decco.stringToJson, v.username)
              ],
              [
                "password",
                Decco.optionToJson(Decco.stringToJson, v.password)
              ]
            ]);
}

function cauthArgs_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var username = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "username"), null));
  if (username.TAG === /* Ok */0) {
    var password = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "password"), null));
    if (password.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                username: username._0,
                password: password._0
              }
            };
    }
    var e = password._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".password" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = username._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".username" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function cauthPost_encode(v) {
  return Js_dict.fromArray([
              [
                "args",
                cauthArgs_encode(v.args)
              ],
              [
                "method",
                Decco.stringToJson(v.method)
              ],
              [
                "back",
                Decco.stringToJson(v.back)
              ]
            ]);
}

function cauthPost_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var args = cauthArgs_decode(Belt_Option.getWithDefault(Js_dict.get(dict$1, "args"), null));
  if (args.TAG === /* Ok */0) {
    var method = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "method"), null));
    if (method.TAG === /* Ok */0) {
      var back = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "back"), null));
      if (back.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  args: args._0,
                  method: method._0,
                  back: back._0
                }
              };
      }
      var e = back._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".back" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = method._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".method" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = args._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".args" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var match = process.env.NODE_ENV;

var isDevelopment = match === "development" ? true : false;

function backendMethod(param) {
  if (typeof param !== "number") {
    return "Password";
  }
  switch (param) {
    case /* GitHub */0 :
        return "Github";
    case /* Google */1 :
        return "Google";
    case /* BitBucket */2 :
        return "BitBucket";
    case /* OpenID */3 :
        return "OpenID";
    case /* OpenIDConnect */4 :
        return "OpenIDConnect";
    case /* SAML */5 :
        return "SAML2";
    
  }
}

function idpParams(back, method_) {
  return {
          args: {
            username: undefined,
            password: undefined
          },
          method: method_,
          back: back
        };
}

function createParams(backend, back) {
  if (typeof backend === "number") {
    switch (backend) {
      case /* GitHub */0 :
          return idpParams(back, "Github");
      case /* Google */1 :
          return idpParams(back, "Google");
      case /* BitBucket */2 :
          return idpParams(back, "BitBucket");
      case /* OpenID */3 :
          return idpParams(back, "OpenID");
      case /* OpenIDConnect */4 :
          return idpParams(back, "OpenIDConnect");
      case /* SAML */5 :
          return idpParams(back, "SAML2");
      
    }
  } else {
    var creds = backend._0;
    return {
            args: {
              username: creds.username,
              password: creds.password
            },
            method: "Password",
            back: back
          };
  }
}

function fakeLogin(backend) {
  if (typeof backend === "number") {
    if (backend !== 0) {
      
    } else {
      Cookie$JustgageReasonCookie.setString("auth_pubtkt", "cid%3D11%3Buid%3DGitHub User%3Bvaliduntil%3D1606139056.416925");
    }
  } else {
    Cookie$JustgageReasonCookie.setString("auth_pubtkt", "cid%3D11%3Buid%3D" + (backend._0.username + "%3Bvaliduntil%3D1606139056.416925"));
  }
  return RescriptReactRouter.push("/");
}

var getUser = SFCookie$SFUI.CauthCookie.getUser;

function Hook(Fetcher) {
  var RemoteApi = RemoteApi$SFUI.API(Fetcher);
  var use = function (param) {
    var match = React.useState(function () {
          return /* NotAsked */0;
        });
    var setState = match[1];
    var state = match[0];
    var authenticate = function (action) {
      if (action) {
        var backend = action._0;
        if (isDevelopment) {
          return fakeLogin(backend);
        } else {
          Curry._3(RemoteApi.justPost, "/auth/login", cauthPost_encode(createParams(backend, action._1)), (function (r) {
                  var s = RemoteApi$SFUI.updateRemoteData(state, r);
                  return Curry._1(setState, (function (param) {
                                return s;
                              }));
                }));
          return ;
        }
      }
      SFCookie$SFUI.CauthCookie.remove(undefined);
      return Curry._1(setState, (function (param) {
                    return /* NotAsked */0;
                  }));
    };
    return [
            state,
            authenticate
          ];
  };
  return {
          RemoteApi: RemoteApi,
          use: use
        };
}

exports.cauthArgs_encode = cauthArgs_encode;
exports.cauthArgs_decode = cauthArgs_decode;
exports.cauthPost_encode = cauthPost_encode;
exports.cauthPost_decode = cauthPost_decode;
exports.isDevelopment = isDevelopment;
exports.backendMethod = backendMethod;
exports.idpParams = idpParams;
exports.createParams = createParams;
exports.fakeLogin = fakeLogin;
exports.getUser = getUser;
exports.Hook = Hook;
/* match Not a pure module */
